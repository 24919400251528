import * as React from 'react'
import { Component } from 'react'
import { connect } from 'react-redux';
import { RouteComponentProps } from "react-router-dom";
import { withRouter, GLGlobal, InvitationType, PrimaryLanguage } from 'gl-commonui';
import { SchoolLocale } from "@app/locales/localeid";
import { SecondaryTitle } from '@app/components';
import { InvitationTemplateDetail, InvitationTemplateDetailFields } from './components/detail';
import { InvitationTemplateModel } from '@app/service/invitation-template/index';
import { StateType } from '@app/states';
import { create, goToList, getNew, getRegions } from '@app/states/invitation-template';
import { RegionModel } from '@app/service/admin/regions';

interface InvitationTemplateCreateProps extends RouteComponentProps<any> { 
    langLoaded?: string
    template?: InvitationTemplateModel
    regions?: RegionModel[]
    create: (model) => void
    goToList: (schoolId) => void
    getNew: () => void
    getRegions: (invitationTYpe) => void
}
interface InvitationTemplateCreateStates extends InvitationTemplateDetailFields {
    invitationType?: InvitationType,
    language?: PrimaryLanguage
}

@withRouter
@connect(({intl: { langLoaded }, invitationTemplate: { model, regions }}: StateType)=>({
    template: model, regions, langLoaded
}), { create, goToList, getNew, getRegions })
export class InvitationTemplatesNewPage extends Component<InvitationTemplateCreateProps, InvitationTemplateCreateStates> {
    constructor(props, context) {
        super(props, context);
        this.handleSaveTemplate = this.handleSaveTemplate.bind(this),
        this.handleCancelTemplate = this.handleCancelTemplate.bind(this)
    }
    componentWillReceiveProps(nextProps) {
        if (this.props.langLoaded != nextProps.langLoaded) {
            this.setState({ langLoaded: nextProps.langLoaded });
        }
    }
    componentDidMount() {
        this.props.getNew();
    }
    handleSaveTemplate(err, values) {
        if (!err) {
            this.props.create(values);
        }
    }    
    handleCancelTemplate() {
        const schoolId = this.props.match.params.schoolId;
        this.props.goToList(schoolId);
    }
    render() {
        const { langLoaded, template, regions, getRegions } = this.props;
        return (
            <div className='content-layout'>
                <div className="invitation-template-detail">
                    <SecondaryTitle title={SchoolLocale.InvitationTemplatePageTitleNew} />
                    <InvitationTemplateDetail 
                        template={template}
                        regions={regions}
                        langLoaded={langLoaded}
                        primaryLanguage={PrimaryLanguage.English} 
                        getRegions = {getRegions}
                        onSaveTemplate = {this.handleSaveTemplate}
                        onCancelTemplate = {this.handleCancelTemplate}
                    />                    
                </div>
            </div>
        );
    }
}