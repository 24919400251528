import React, { Component } from 'react'
import { Upload, Icon } from 'antd-min';
import { NotificationType, MessageHelper } from 'gl-commonui';
import { SchoolLocale } from '@app/locales/localeid';
import { fmtMsg, getImageDimensions, TemplateBackgroundImageLayout } from '@app/util';

interface BackgroundImageProps {
    file?: string
    imageLayout?: TemplateBackgroundImageLayout
    fileChanged: (file, fileWidth) => void
}
interface BackgroundImageStates {
    imageUrl?: string
    fileList: any
    loading: boolean
    fileWidth: string
}

export const defaultFileWidth = '100%';

export class BackgroundImage extends React.Component<BackgroundImageProps, BackgroundImageStates> {
    constructor(props, context) {
        super(props, context);
        this.state = {
            fileList: [],
            loading: false,
            fileWidth: defaultFileWidth
        };
    }
    componentWillReceiveProps(nextProps) {
        const imageChanged = this.state.imageUrl !== null && !this.state.imageUrl;
        const imageLayoutChanged = this.props.imageLayout !== nextProps.imageLayout;
        if (imageChanged || imageLayoutChanged) {
            imageChanged && this.setState({ imageUrl: nextProps.file });
            this.onFileChanged(imageChanged ? nextProps.file : this.state.imageUrl, nextProps.imageLayout);
        }
    }
    getBase64(img, callback) {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }

    beforeUpload(file) {
        const extValid = (file.type == 'image/png' || file.type == 'image/gif' || file.type == 'image/jpeg' || file.type == 'image/jpg');
        if (!extValid) {
            MessageHelper.Message(NotificationType.Failed, fmtMsg({ id: SchoolLocale.SettingsAvatarType }));
            return false;
        }
        const isLt2M = file.size / 1024 / 1014 < 2;
        if (!isLt2M) {
            MessageHelper.Message(NotificationType.Failed, fmtMsg({ id: SchoolLocale.InvitationTemplateFieldBackgroundImageSize }));
            return false;
        }
        this.setState(({ fileList }) => ({
            fileList: [...fileList, file],
        }));
        this.getBase64(file, imageUrl => {
            this.setState({ imageUrl: imageUrl });
            this.onFileChanged(imageUrl, this.props.imageLayout);
        });
        return false;
    }
    onFileChanged(imageUrl, imageLayout) {
        const { fileChanged } = this.props;
        getImageDimensions(imageUrl).then(({width, relativeWidth}) => {
            const rWidth = imageLayout == TemplateBackgroundImageLayout.stretch ? defaultFileWidth :  `${relativeWidth}%`;
            fileChanged && fileChanged(imageUrl, rWidth);
            this.setState({ fileWidth: rWidth});
        });        
    }
    render() {
        const { fileChanged } = this.props;
        const uploadButton = (
            <div className='background-image-upload-button'>
                <Icon type={this.state.loading ? 'loading' : 'plus'} />
                <div className="ant-upload-text">{fmtMsg({ id: SchoolLocale.SettingsAvatarUploadButton })}</div>
            </div>
        );
        const imageUrl = this.state.imageUrl;
        return (
            <div className="background-image-container">
                <Upload
                    name="background-image"
                    listType="picture-card"
                    className="background-image-uploader"
                    showUploadList={false}
                    action=""
                    accept=".jpeg,.jpg,.png,.gif"
                    fileList={this.state.fileList}
                    beforeUpload={this.beforeUpload.bind(this)}
                >
                    {imageUrl ? <>
                        <div style={{backgroundImage: `url(${imageUrl})`, backgroundSize: `100% auto`}} ></div>
                        <span className="background-image-uploader-actions" onClick={(e) => e.stopPropagation()}>
                            <Icon type="delete" onClick={() => {
                                this.setState({ imageUrl: null, fileList: [] });
                                fileChanged && fileChanged(null, defaultFileWidth);
                            }} />
                        </span>
                    </> : uploadButton}
                </Upload>
                {this.props.children}
            </div>
        );
    }
}