import * as React from 'react';
import { Component } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from "react-router-dom";
import {withRouter, GLAction, GLGlobal, RoleName} from 'gl-commonui';
import { SchoolLocale } from "@app/locales/localeid";
import { SecondaryTitle } from '@app/components';
import { InvitationTemplateDetail, InvitationTemplateDetailFields } from './components/detail';
import { InvitationTemplateModel } from '@app/service/invitation-template/index';
import { StateType } from '@app/states';
import { get, update, remove, goToList, getRegions } from '@app/states/invitation-template';
import {GSSchoolAction, InvitationChannel} from '@app/util/enum';
import { PageTitleDelBtn } from '@app/components/school/school-page-title-btn';
import { RegionModel } from '@app/service/admin/regions';

interface InvitationTemplateEditProps extends RouteComponentProps<any> {
    langLoaded?: string
    template?: InvitationTemplateModel
    regions?: RegionModel[]
    get: (id) => void,
    update: (model) => void,
    remove: (id) => void,
    goToList: (schoolId) => void
    getRegions: (invitationTYpe) => void
}
interface InvitationTemplateEditStates extends InvitationTemplateDetailFields { }

@withRouter
@connect(({intl: { langLoaded }, invitationTemplate: {model, regions}}: StateType) => ({
    template: model, langLoaded, regions
}), {
    get, update, remove, goToList, getRegions
})
export class InvitationTemplatesEditPage extends Component<InvitationTemplateEditProps, InvitationTemplateEditStates> {
    constructor(props, context) {
        super(props, context);
        this.handleSaveTemplate = this.handleSaveTemplate.bind(this);
        this.handleDeleteTemplate = this.handleDeleteTemplate.bind(this);
        this.handleCancelTemplate = this.handleCancelTemplate.bind(this);
    }
    componentWillReceiveProps(nextProps) {
        if (this.props.langLoaded != nextProps.langLoaded) {
            this.setState({ langLoaded: nextProps.langLoaded });
        }
    }
    componentDidMount() {
        const id = this.props.match.params.templateId;
        this.props.get(id);
    }
    handleSaveTemplate(err, values) {
        if (!err) {
            this.props.update(values);
        }
    }
    handleCancelTemplate() {
        const schoolId = this.props.match.params.schoolId;
        this.props.goToList(schoolId);
    }
    handleDeleteTemplate(templateId) {
        this.props.remove(templateId);
    }
    isSAEdit() {
        const isSARole = GLGlobal.loginInfo() && GLGlobal.loginInfo().profile && (GLGlobal.loginInfo().profile.roles.indexOf(RoleName.systemAdmin) > -1);
        if (this.props.template.invitationChannel === 1) {
            return isSARole;
        } else {
            return true;
        }
    }
    render() {
        const { langLoaded, template, regions, getRegions } = this.props;
        return (
            <div className='content-layout'>
                <div className="invitation-template-detail invitation-template-detail-edit">
                    <SecondaryTitle title={SchoolLocale.InvitationTemplatePageTitleEdit} >
                    { this.isSAEdit() &&
                        <GLAction action={GSSchoolAction.DeleteTemplate}>
                            <PageTitleDelBtn type="delete" className="delete" labelId={SchoolLocale.BtnDelete} deleteTitleId={SchoolLocale.InvitationTemplatePromptDeleteMessage} delete={()=>{
                                this.handleDeleteTemplate(this.props.match.params.templateId)
                            }} />
                        </GLAction>
                    }
                    </SecondaryTitle>
                    <InvitationTemplateDetail
                        langLoaded={langLoaded}
                        template={template}
                        primaryLanguage={template.language}
                        regions={regions}
                        isEdit={true}
                        deletable = {true}
                        getRegions = {getRegions}
                        onSaveTemplate = {this.handleSaveTemplate}
                        onDeleteTemplate = {this.handleDeleteTemplate}
                        onCancelTemplate = {this.handleCancelTemplate}
                    />
                </div>
            </div>
        );
    }
}
