import * as React from "react";
import { Component } from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { RouteComponentProps, Link } from "react-router-dom";
import { Icon, Input } from "antd-min";
import { TableProps } from "antd/lib/table";
import { withRouter, GLUtil, PaginationParams, GLAction, GLGlobal, RoleName } from "gl-commonui";
import { PathConfig } from "@app/config/pathconfig";
import { SchoolLocale } from "@app/locales/localeid";
import { MainTitle, ListStatesStore, ListStatesRadios, Container } from "@app/components";
import { GLGrid, GLGridSorter, GLGridColumnProps } from "@app/components/gl-grid";
import { InvitationTemplateModel, InvitationTemplateModelPropsName } from "@app/service/invitation-template";
import { StateType } from "@app/states";
import { getItemsBy } from "@app/states/invitation-template";
import { GSSchoolAction, fmtMsg, GSAdminAction, CONSTS, ContextHelper, InvitationChannel } from "@app/util";

interface InvitationTemplatesPageProps {
    list: any[];
    total: number;
    activeCnt: number;
    loading: boolean;
    getItemsBy: (id) => void;
}

@withRouter
@connect(({ invitationTemplate: { list, total, activeCnt } }: StateType) => ({ list, total, activeCnt }), {
    getItemsBy,
})
export class InvitationTemplatesPage extends Component<
    RouteComponentProps<any> & InvitationTemplatesPageProps,
    { listState }
> {
    pagination: PaginationParams = new PaginationParams(1, 20);
    tableProps: TableProps<InvitationTemplateModel> = {
        pagination: this.pagination,
        rowClassName: (record) => (record.disabled ? "list-disabled" : ""),
        onChange: this.handleTablePageChange.bind(this),
    };
    listStateStore: ListStatesStore;
    private sorter: GLGridSorter;
    private columnSortingMap: Map<string, string> = new Map<string, string>([
        [InvitationTemplateModelPropsName.invitationTypeText, "invitationType"],
        [InvitationTemplateModelPropsName.invitationChannelText, "invitationChannel"],
    ]);
    private filter: string;
    private emailIcon = `<i aria-label="icon: mail" class="anticon anticon-mail"><svg viewBox="64 64 896 896" focusable="false" class="" data-icon="mail" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M928 160H96c-17.7 0-32 14.3-32 32v640c0 17.7 14.3 32 32 32h832c17.7 0 32-14.3 32-32V192c0-17.7-14.3-32-32-32zm-40 110.8V792H136V270.8l-27.6-21.5 39.3-50.5 42.8 33.3h643.1l42.8-33.3 39.3 50.5-27.7 21.5zM833.6 232L512 482 190.4 232l-42.8-33.3-39.3 50.5 27.6 21.5 341.6 265.6a55.99 55.99 0 0 0 68.7 0L888 270.8l27.6-21.5-39.3-50.5-42.7 33.2z"></path></svg></i>`;
    private smsIcon = `<i aria-label="icon: message" class="anticon anticon-message"><svg viewBox="64 64 896 896" focusable="false" class="" data-icon="message" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M464 512a48 48 0 1 0 96 0 48 48 0 1 0-96 0zm200 0a48 48 0 1 0 96 0 48 48 0 1 0-96 0zm-400 0a48 48 0 1 0 96 0 48 48 0 1 0-96 0zm661.2-173.6c-22.6-53.7-55-101.9-96.3-143.3a444.35 444.35 0 0 0-143.3-96.3C630.6 75.7 572.2 64 512 64h-2c-60.6.3-119.3 12.3-174.5 35.9a445.35 445.35 0 0 0-142 96.5c-40.9 41.3-73 89.3-95.2 142.8-23 55.4-34.6 114.3-34.3 174.9A449.4 449.4 0 0 0 112 714v152a46 46 0 0 0 46 46h152.1A449.4 449.4 0 0 0 510 960h2.1c59.9 0 118-11.6 172.7-34.3a444.48 444.48 0 0 0 142.8-95.2c41.3-40.9 73.8-88.7 96.5-142 23.6-55.2 35.6-113.9 35.9-174.5.3-60.9-11.5-120-34.8-175.6zm-151.1 438C704 845.8 611 884 512 884h-1.7c-60.3-.3-120.2-15.3-173.1-43.5l-8.4-4.5H188V695.2l-4.5-8.4C155.3 633.9 140.3 574 140 513.7c-.4-99.7 37.7-193.3 107.6-263.8 69.8-70.5 163.1-109.5 262.8-109.9h1.7c50 0 98.5 9.7 144.2 28.9 44.6 18.7 84.6 45.6 119 80 34.3 34.3 61.3 74.4 80 119 19.4 46.2 29.1 95.2 28.9 145.8-.6 99.6-39.7 192.9-110.1 262.7z"></path></svg></i>`;
    constructor(props, context) {
        super(props, context);
        this.listStateStore = new ListStatesStore("templates-list-states", "templates-list-states");
        this.sorter = {
            columnName: InvitationTemplateModelPropsName.name,
            columnSortName: InvitationTemplateModelPropsName.name,
            ascending: true,
        };
        this.filter = "";
        this.state = {
            listState: this.listStateStore.getState(),
        };
        this.onSearch = this.onSearch.bind(this);
        this.onColumnSorting = this.onColumnSorting.bind(this);
    }

    getColumns(): GLGridColumnProps<InvitationTemplateModel>[] {
        return [
            {
                title: SchoolLocale.InvitationTemplateFieldName,
                dataIndex: InvitationTemplateModelPropsName.name,
                width: "25%",
                render: (text, template, index) => {
                    if (GLGlobal.isActionValid(GSSchoolAction.InvitationTemplate)) {
                        return `<a href="${GLUtil.pathStringify(PathConfig.InvitationTemplateEdit, {
                            templateId: template.id,
                        })}" > <span>${text}</span> </a>`;
                    } else {
                        return `<span>${text}</span>`;
                    }
                },
                className: "navigation-col",
            },
            {
                title: SchoolLocale.InvitationTemplateFieldRegion,
                dataIndex: InvitationTemplateModelPropsName.regionEnglishName,
                width: "15%",
                render: (text, template, index) => {
                    const regionName = text ? text : CONSTS.InvitationTemplateRegion.DefaultRegion.name;
                    return `<span>${regionName}</span>`;
                },
            },
            {
                title: SchoolLocale.InvitationTemplateFieldSubject,
                dataIndex: InvitationTemplateModelPropsName.title,
                width: "25%",
            },
            {
                title: SchoolLocale.InvitationTemplateFieldLanguage,
                dataIndex: InvitationTemplateModelPropsName.language,
                width: "10%",
            },
            {
                title: SchoolLocale.InvitationTemplateFieldType,
                dataIndex: InvitationTemplateModelPropsName.invitationTypeText,
                width: "15%",
            },
            {
                title: SchoolLocale.InvitationTemplateFieldChannel,
                dataIndex: InvitationTemplateModelPropsName.invitationChannelText,
                width: "10%",
                render: (text, template, index) => {
                    const icon = template.invitationChannel == InvitationChannel.Email ? this.emailIcon : this.smsIcon;
                    return `${icon}<span>${fmtMsg({ id: text })}</span>`;
                },
            },            
        ];
    }

    getInvitationTemplates(sorter: GLGridSorter, filter?: string) {
        this.props.getItemsBy({
            query: {
                ...this.listStateStore.getUrlQuery(this.pagination.toRequest()),
                filterText: filter,
                sortBy: sorter ? sorter.columnSortName : null,
                isDescending: sorter && sorter.columnSortName ? !sorter.ascending : null,
            },
        });
    }

    componentDidMount() {
        this.getInvitationTemplates(this.sorter, this.filter);
    }

    onSearch(value) {
        this.filter = value;
        this.pagination.current = 1;
        this.getInvitationTemplates(this.sorter, value);
    }

    onColumnSorting(sorter: GLGridSorter) {
        const columnName = this.columnSortingMap.has(sorter.columnSortName)
            ? this.columnSortingMap.get(sorter.columnSortName)
            : sorter.columnSortName;
        this.sorter = { ...sorter, columnSortName: columnName };
        this.getInvitationTemplates(this.sorter, this.filter);
    }

    handleTablePageChange(pagination, filters, sorter) {
        this.pagination.current = pagination.current;
        this.getInvitationTemplates(this.sorter, this.filter);
    }

    render() {
        const {
            loading,
            list,
            total,
            activeCnt,
            match: {
                params: { schoolId },
            },
        } = this.props;
        this.pagination.total = ListStatesStore.getStateCnt(total, activeCnt, this.state);
        this.tableProps.pagination = this.pagination;
        this.tableProps.dataSource = list;
        this.tableProps.loading = loading;
        const placeHolder = SchoolLocale.InvitationTemplatePageSearchPlaceHolderMultiRegion;
        return (
            <Container fullWidth className="invitation-template-list">
                <MainTitle title={SchoolLocale.InvitationTemplatePageTitleList} />
                <div>
                    <div className="template-filter">
                        <Input.Search placeholder={fmtMsg({ id: placeHolder })} onSearch={this.onSearch} />
                    </div>
                    <div className="templates-list-states">
                        <ListStatesRadios
                            allCnt={this.props.total}
                            activeCnt={this.props.activeCnt}
                            value={this.state.listState}
                            onChange={(state) => {
                                this.setState({ listState: state });
                                this.listStateStore.setState(state);
                                (this.tableProps.pagination as PaginationParams).current = 1;
                                this.handleTablePageChange(new PaginationParams(1, 30), null, null);
                            }}
                        ></ListStatesRadios>
                        <GLAction action={GSSchoolAction.NewTemplate}>
                            <div className="content-header">
                                <Link
                                    to={GLUtil.pathStringify(PathConfig.InvitationTemplateCreate, {
                                        schoolId: schoolId,
                                    })}
                                >
                                    <Icon type="plus-circle-o" />
                                    <FormattedMessage id={SchoolLocale.InvitationTemplatePageTitleNew} />
                                </Link>
                            </div>
                        </GLAction>
                    </div>
                    <GLGrid
                        columns={this.getColumns()}
                        defaultSorter={this.sorter}
                        onColumnSorting={this.onColumnSorting}
                        {...this.tableProps}
                        loading={false}
                    />
                </div>
            </Container>
        );
    }
}
