import React, { Component } from 'react'
import { Modal, Button } from 'antd-min';
import { SchoolLocale } from '@app/locales/localeid';
import { fmtMsg } from '@app/util';
import { GLRichTextEditor } from '@app/components';
import { GLLocale } from 'gl-commonui';
import classNames from 'classnames';

interface PreviewProps {
    visible: boolean
    templateHtml: string
    backgroundImage?: string
    backgroundImageWidth?: string
    onCancel?: (value: any) => void
}
  
export const PreviewTemplate = (props: PreviewProps) => {
    const { visible, templateHtml, backgroundImage, backgroundImageWidth, onCancel } = props;
    return (
        <Modal 
            className = "invitation-template-preview"
            wrapClassName = "vertical-center-modal"
            title = {fmtMsg({id: SchoolLocale.InvitationTemplatePreviewDialogTitle})}
            visible = {visible}
            footer={[
                <Button type="primary" onClick={onCancel} key='ok'>
                    {fmtMsg(GLLocale.Ok)}
                </Button>
            ]}
            closable = {false}
            destroyOnClose = {true}
        >
            <div className='invitation-template-preview-body' style={{backgroundImage: `url(${backgroundImage})`, backgroundSize: `${backgroundImageWidth} auto`}} >
            {/* <GLRichTextEditor readOnly={true} editorHtml={templateHtml} /> */}
            <span dangerouslySetInnerHTML={{ __html: templateHtml }} />
            </div>
        </Modal>
    )
}
