import * as React from 'react';
import { Component } from 'react';
import { Form, Row, Col, Select, Input, Menu, Checkbox } from 'antd-min';
import { GLGlobal, GLLocale, GLForm, FormHelper, ComparisonOperator, GLFormComponentProps, FormItemsLayout, InvitationType, GLUtil, alignPop, PrimaryLanguage, RoleName, Role } from 'gl-commonui';
import { PathConfig } from '@app/config/pathconfig';
import { ContextHelper, InvitationHelper, extendForm, CONSTS, fmtMsg, convertToPlain } from '@app/util/index';
import { SchoolLocale, GSAdminLocale } from '@app/locales/localeid';
import {
    GLRichTextEditor, RichTextDecorator, GLRichTextEditorToolbarOptions,
    InvitationTemplateTagComponent, InvitationTemplateTagHelper, InvitationTemplateTagDropDown,
    SubmitBtns, EditDelBtns
} from '@app/components';
import { InvitationTemplateModelPropsName, InvitationTemplateModel } from '@app/service/invitation-template/index';
import { GSSchoolAction, InvitationChannel, TemplateBackgroundImageLayout } from '@app/util/enum';
import { FormattedMessage } from 'react-intl';
import { RegionSchoolRoleModel } from '@app/service/admin/regions';
import { SchoolModel } from '@app/service/schools';
import { BackgroundImage, defaultFileWidth } from './background-image';
import { PreviewTemplate } from './preview';

export interface InvitationTemplateDetailFields {
    langLoaded?: string
    template?: InvitationTemplateModel
    deletable?: boolean
    primaryLanguage?: string
    isEdit?: boolean
    regions?: RegionSchoolRoleModel[]
    getRegions?: (invitationType) => void
    onSaveTemplate?: (err, values) => void
    onCancelTemplate?: (event) => void
    onDeleteTemplate?: (id) => void
}

interface InvitationTemplateDetailProps extends GLFormComponentProps, InvitationTemplateDetailFields { }

interface InvitationTemplateDetailStates {
    invitationType?: InvitationType
    invitationTypeOptions?: JSX.Element[] | null
    languageOptions?: JSX.Element[] | null
    templateTagOptions?: JSX.Element[] | null
    templateTagMenus?: JSX.Element[] | null
    dataDeleting?: boolean
    regionOptions?: JSX.Element[] | null
    regionDisabled?: boolean
    schoolOptions?: JSX.Element[] | null
    isSchoolRequired?: boolean
    backgroundImageStretch?: boolean
    showPreviewModal?: boolean
    invitationChannelOptions?: JSX.Element[] | null
    templateToolbarOptions?: GLRichTextEditorToolbarOptions
}

const { renderFormItem } = FormHelper;
const maxTemplateTextLength = 50000000;
const maxSMSTextLength = 160;

@GLForm.create()
export class InvitationTemplateDetail extends Component<InvitationTemplateDetailProps, InvitationTemplateDetailStates> {
    TemplateEditorDecorators = this.getTemplateEditorDecorators()
    domEditor: GLRichTextEditor
    private currentRegion: RegionSchoolRoleModel
    private languages: Map<string, string>
    private previewText: string
    private backgroundImage: string
    private backgroundImageWidth: string
    constructor(props) {
        super(props);
        this.state = {
            invitationType: null,
            invitationTypeOptions: this.getInvitationTypeOptions(),
            languageOptions: this.getLanguageOptions(),
            templateTagMenus: null,
            dataDeleting: false,
            isSchoolRequired: this.isSchoolRequired(),
            backgroundImageStretch: false,
            showPreviewModal: false,
            invitationChannelOptions: this.getInvitationChannelOptions()
        };
        this.currentRegion = InvitationHelper.getRegionRoleModel(CONSTS.InvitationTemplateRegion.DefaultRegion);
        this.languages = new Map<string, string>();
        this.previewText = null;
        this.backgroundImage = null;
        this.backgroundImageWidth = defaultFileWidth;
        this.setDomEditorRef = this.setDomEditorRef.bind(this);
        this.onPreview = this.onPreview.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.handleInvitationTypeChange = this.handleInvitationTypeChange.bind(this);
        this.handleRegionChange = this.handleRegionChange.bind(this);
        this.handleTemplateTextChange = this.handleTemplateTextChange.bind(this);
        this.handleTemplateTagChange = this.handleTemplateTagChange.bind(this);
        this.handleInvitationChannelChange = this.handleInvitationChannelChange.bind(this);
        this.confirmDeleteItem = this.confirmDeleteItem.bind(this);
        this.processEmptyLine = this.processEmptyLine.bind(this);
    }
    setDomEditorRef(ref) {
        this.domEditor = ref;
    }
    isSARole() {
        return GLGlobal.loginInfo() && GLGlobal.loginInfo().profile && (GLGlobal.loginInfo().profile.roles.indexOf(RoleName.systemAdmin) > -1);
    }
    isSAEdit() {
        if (this.props.template.invitationChannel === 1) {
            return this.props.isEdit && this.isSARole();
        } else {
            return true;
        }
    }
    getOption(key, value, text) {
        return <Select.Option key={key} value={value}>{text}</Select.Option>
    }
    getInvitationTypeOptions() {
        const invitationTypes = InvitationHelper.role2InvitationType[ContextHelper.getUserRoleForInvitationType()];
        if (!invitationTypes) { return null; }
        return [...invitationTypes.keys()].map((key, index) => {
            return this.getOption(key, key, fmtMsg({ id: invitationTypes.get(key) }))
        });
    }
    getLanguageOptions(primaryLanguage?, languages?: Map<string, string>) {
        this.languages = languages ? languages : InvitationHelper.generateTemplateLanguage([PrimaryLanguage.English, primaryLanguage ? primaryLanguage : this.props.primaryLanguage]);
        return [...this.languages.keys()].map((key, index) => {
            return this.getOption(key, key, fmtMsg({ id: this.languages.get(key) }))
        });
    }
    getInvitationChannelOptions() {
        const invitationChannels = InvitationHelper.invitationChannel2Text;
        if (!invitationChannels) { return null; }
        return [...invitationChannels.keys()].map((key, index) => {
            return this.getOption(key, key, fmtMsg({ id: invitationChannels.get(key) }))
        });
    }
    getTemplateTagOptions(invitationType) {
        if (invitationType == null || invitationType == undefined) { return null; }
        const invitationTemplateTags = InvitationHelper.invitationType2InvitationTag[invitationType];
        return [...invitationTemplateTags.keys()].map((key, index) => {
            return this.getOption(key, key, invitationTemplateTags.get(key))
        });
    }
    isTemplateTagValid(tag) {
        return tag != "";
    }
    getMenu(key, value, text) {
        return <Menu.Item key={value}>{text}</Menu.Item>
    }
    getTemplateTagMenus(invitationType) {
        if (invitationType == null || invitationType == undefined) { return null; }
        const invitationTemplateTags = InvitationHelper.invitationType2InvitationTag[invitationType];
        return [...invitationTemplateTags.keys()].map((key, index) => {
            return this.getMenu(index, key, invitationTemplateTags.get(key))
        });
    }
    convertTemplateTag(tag) {
        return `{${tag}}`;
    }
    setTemplateTagState(invitationType) {
        const regionDisabled = (invitationType == InvitationType.globalHead
            || invitationType == InvitationType.systemAdmin
            || invitationType == InvitationType.trainingAdmin
            || invitationType == InvitationType.contentAdmin);
        this.setState({
            invitationType: invitationType,
            templateTagOptions: this.getTemplateTagOptions(invitationType),
            templateTagMenus: this.getTemplateTagMenus(invitationType),
            regionDisabled: regionDisabled
        });
        if (regionDisabled) {
            this.setState({
                languageOptions: this.getLanguageOptions()
            });
            this.props.form.setFieldsValue({ regionId: CONSTS.InvitationTemplateRegion.DefaultRegion.id });
            this.props.form.setFieldsValue({ language: PrimaryLanguage.English });
        }
    }
    getTemplateEditorDecorators(): RichTextDecorator[] {
        return [
            {
                strategy: InvitationTemplateTagHelper.FindTemplateTagEntities,
                component: InvitationTemplateTagComponent
            }
        ];
    }
    componentWillReceiveProps(nextProps) {
        const { form, template: { invitationType, regionId, backgroundImage }, regions, primaryLanguage, langLoaded } = this.props;
        if ('invitationType' in nextProps.template && this.state.invitationType == null) {
            this.setTemplateTagState(nextProps.template.invitationType);
        }
        if ('invitationChannel' in nextProps.template && this.state.templateToolbarOptions == undefined) {
            this.setState({
                templateToolbarOptions: nextProps.template.invitationChannel == InvitationChannel.Email ?
                    GLRichTextEditorToolbarOptions.All : GLRichTextEditorToolbarOptions.History
            });
        }
        if ('backgroundImage' in nextProps.template && this.props.template.backgroundImage != nextProps.template.backgroundImage) {
            this.backgroundImage = nextProps.template.backgroundImage;
        }
        if ('backgroundImageLayout' in nextProps.template && this.props.template.backgroundImageLayout != nextProps.template.backgroundImageLayout) {
            this.setState({ backgroundImageStretch: nextProps.template.backgroundImageLayout == TemplateBackgroundImageLayout.stretch });
        }
        if (regions != nextProps.regions) {
            const currentRegionId = form.getFieldValue(InvitationTemplateModelPropsName.regionId);
            if (currentRegionId) {
                const isValid = nextProps.regions.find(region => region.id == currentRegionId);
                if (!isValid) {
                    form.setFieldsValue({ regionId: null, schoolId: null });
                    this.setState({
                        schoolOptions: null
                    });
                }
            }
            this.setState({
                regionOptions: this.getRegionOptions(nextProps.regions)
            });
        }
        if ('regionId' in nextProps.template && regionId !== nextProps.template.regionId) {
            form.setFieldsValue({ regionId: nextProps.template.regionId });
            this.handleRegionChange(nextProps.template.regionId, true, nextProps);
        }
        if (langLoaded != nextProps.langLoaded) {
            this.setState({
                invitationTypeOptions: this.getInvitationTypeOptions(),
                languageOptions: this.getLanguageOptions(nextProps.primaryLanguage, this.languages)
            });
        }
    }
    getRegionOptions(regions: RegionSchoolRoleModel[]) {
        return regions.map((region, index) => {
            return this.getOption(index, region.id, region.englishName)
        });
    }
    getSchoolOptions(schools: SchoolModel[]) {
        return schools.map((school, index) => {
            return this.getOption(index, school.id, school.name)
        });
    }
    isSchoolRequired(regionRoles?: RegionSchoolRoleModel): boolean {
        if (regionRoles) {
            return (regionRoles.role == Role.AccountManager || regionRoles.role == Role.SchoolAdmin);
        }
        else {
            return false;
        }
    }
    handleRegionChange(regionId, language?, props?) {
        const { regions, form } = props ? props : this.props;
        if (!regions || regions.length == 0) { return; }
        const region = regions.find(region => region.id == regionId);
        this.currentRegion = region;
        const regionLang = region ? region.primaryLanguage : '';
        let schools = region.schools ? [...region.schools] : null;
        if (schools && (region.role != Role.AccountManager && region.role != Role.SchoolAdmin)) {
            schools.unshift(CONSTS.InvitationTemplateSchool.DefaultSchool);
        }
        this.setState({
            languageOptions: this.getLanguageOptions(regionLang),
            schoolOptions: schools ? this.getSchoolOptions(schools) : null,
            isSchoolRequired: this.isSchoolRequired(region)
        });
        if (regionLang && language != true) { form.setFieldsValue({ language: regionLang }); }
        language != true && form.setFieldsValue({ schoolId: null });
    }
    handleInvitationTypeChange(invitationType) {
        const { form, getRegions } = this.props;
        getRegions(invitationType);
        form.setFieldsValue({ regionId: null, schoolId: null });
        this.setState({
            schoolOptions: null,
            isSchoolRequired: false
        });
        if (invitationType != InvitationType.parent) {
            this.backgroundImage = null;
            this.backgroundImageWidth = defaultFileWidth;
        }
        this.setTemplateTagState(invitationType);
    }
    handleInvitationChannelChange(invitationChannel) {
        this.setState({
            templateToolbarOptions: invitationChannel == InvitationChannel.Email ?
                GLRichTextEditorToolbarOptions.All : GLRichTextEditorToolbarOptions.History
        });
    }
    handleTemplateTagChange(editorState) {
        if (this.domEditor) { this.domEditor.focusEditor(editorState); }
    }
    handleTemplateTextChange(value) {
        const form = this.props.form;
        const oldValue = form.getFieldValue(InvitationTemplateModelPropsName.text);
        if (!oldValue && !value) { return; }
        if (value) {
            const overflowLengthMsg = fmtMsg({ id: GLLocale.FormCprLessThan }, {
                name: fmtMsg({ id: GLLocale.FormFormatLength }, { name: fmtMsg({ id: SchoolLocale.InvitationTemplateFieldContent }) }), value: 500
            });
            if (value.toString().length > maxTemplateTextLength) {
                form.setFields({
                    text: {
                        value: value,
                        errors: [new Error(overflowLengthMsg)]
                    }
                });
            }
            else {
                form.setFieldsValue({ text: value });
            }
        }
        else {
            const requiredMsg = fmtMsg({ id: GLLocale.FormPrefixPleaseInput }, { name: fmtMsg({ id: SchoolLocale.InvitationTemplateFieldContent }) });
            form.setFields({
                text: {
                    value: value,
                    errors: [new Error(requiredMsg)]
                }
            });
        }
    }
    isInvitationTemplateTagValueValid(templateText: string) {
        let matchedTagGroup, matchedTags, start, end;
        while ((matchedTagGroup = InvitationTemplateTagHelper.TemplateTagGroupRegex.exec(templateText)) !== null) {
            //there is one tag at least in this branch, so matchedTags should not be null.
            matchedTags = matchedTagGroup[0].match(InvitationTemplateTagHelper.TemplateTagPattern);
            if (matchedTags && matchedTags.length === 1) { return false };
        }
        return true;
    }
    isTemplateEmpty(templateText: string) {
        const plainText = templateText ? templateText.replace(InvitationTemplateTagHelper.HtmlTagPattern, '').replace(/&nbsp;/g, '').replace(/\n/g, '').trim() : '';
        return plainText == '';
    }
    processEmptyLine(templateText: string) {
        return templateText ? templateText.replace(/<p><\/p>/g, '<p style="min-height:1em;"></p>') : templateText;
    }
    onPreview(showPreview) {
        this.previewText = showPreview ? this.processEmptyLine(this.props.form.getFieldValue(InvitationTemplateModelPropsName.text)) : null;
        this.setState({
            showPreviewModal: showPreview
        });
    }
    onSubmit(e) {
        e.preventDefault();
        const form = this.props.form;
        form.validateFields((err, values) => {
            if (this.isTemplateEmpty(values.text)) {
                const requiredMsg = fmtMsg({ id: GLLocale.FormPrefixPleaseInput }, { name: fmtMsg({ id: SchoolLocale.InvitationTemplateFieldContent }) });
                form.setFields({
                    text: {
                        value: values.text,
                        errors: [new Error(requiredMsg)]
                    }
                });
            }
            else if (this.isInvitationTemplateTagValueValid(values.text)) {
                if (values[InvitationTemplateModelPropsName.invitationChannel] == InvitationChannel.Phone) {
                    //check the length of sms content
                    const smsMessage = convertToPlain(values[InvitationTemplateModelPropsName.text]).trim();
                    const tagTextMap = InvitationTemplateTagHelper.GetInvitationTemplateTagTextMap();
                    const filledSMSMessage = [...tagTextMap.keys()].reduce((pre, cur) => {
                        return pre == "" ? smsMessage.replace(cur, tagTextMap.get(cur)) : pre.replace(cur, tagTextMap.get(cur))
                    }, "");
                    //console.log(smsMessage, filledSMSMessage);
                    if (maxSMSTextLength > 0 && filledSMSMessage.length > maxSMSTextLength)
                    {
                        const templateTextOverflowError = fmtMsg({ id: GLLocale.FormCprLessOrEqualsThan }, {
                            name: fmtMsg({id: SchoolLocale.InvitationTemplateFieldContent}), value: maxSMSTextLength });
                        form.setFields({
                            text: {
                                value: values.text,
                                errors: [new Error(templateTextOverflowError)]
                            }
                        });
                        return;
                    }
                }
                const { onSaveTemplate } = this.props;
                if (ContextHelper.isUserMatchRole(RoleName.systemAdmin)) {
                    values.regionId = values.regionId == CONSTS.InvitationTemplateRegion.DefaultRegion.id ? null : values.regionId;
                }
                values.schoolId = values.schoolId == CONSTS.InvitationTemplateSchool.DefaultSchool.id ? null : values.schoolId;
                values.text = this.processEmptyLine(values.text);
                values.backgroundImage = values.invitationType == InvitationType.parent ? this.backgroundImage : null;
                values.backgroundImageLayout = this.state.backgroundImageStretch ? TemplateBackgroundImageLayout.stretch : TemplateBackgroundImageLayout.auto;
                if (onSaveTemplate) onSaveTemplate(err, values);
            }
            else {
                const templateTagFormatError = fmtMsg({ id: SchoolLocale.InvitationTemplateFieldContentInvalid });
                form.setFields({
                    text: {
                        value: values.text,
                        errors: [new Error(templateTagFormatError)]
                    }
                });
            }
        });
    }
    onCancel(e) {
        e.preventDefault();
        const { onCancelTemplate } = this.props;
        if (onCancelTemplate) onCancelTemplate(e);
    }
    confirmDeleteItem() {
        const { onDeleteTemplate } = this.props;
        const templateId = GLUtil.pathParse(PathConfig.InvitationTemplateEdit).templateId;
        if (onDeleteTemplate) onDeleteTemplate(templateId);
    }

    render() {
        const { form, template, isEdit, deletable } = this.props;
        const { regionDisabled, invitationType, invitationTypeOptions, regionOptions,
            isSchoolRequired, schoolOptions, invitationChannelOptions, languageOptions,
            templateToolbarOptions, templateTagMenus, backgroundImageStretch, showPreviewModal } = this.state;
        const deletePromptWidth = 450;
        const options = {
            formItemProps: { label: null },
            decoratorOptions: { valuePropName: 'checked' }
        }
        const disableRegion = regionDisabled;
        const disableSchool = form.getFieldValue(InvitationTemplateModelPropsName.regionId) == CONSTS.InvitationTemplateRegion.DefaultRegion.id ||
            form.getFieldValue(InvitationTemplateModelPropsName.invitationType) == InvitationType.regionAdmin;
        const isParentTemplate = invitationType == InvitationType.parent && templateToolbarOptions == GLRichTextEditorToolbarOptions.All;
        return (
            <React.Fragment>
                <Form   onSubmit={this.onSubmit}>
                    <FormItemsLayout colTotal={2}>
                        {renderFormItem(form, SchoolLocale.InvitationTemplateFieldName, InvitationTemplateModelPropsName.name,
                            <Input disabled={!this.isSAEdit()} />, template.name, true)}
                        {renderFormItem(form, SchoolLocale.InvitationTemplateFieldType, InvitationTemplateModelPropsName.invitationType,
                            <Select size='large' {...alignPop()} onChange={this.handleInvitationTypeChange} disabled={!this.isSAEdit()}>{...invitationTypeOptions}</Select>,
                            template.invitationType == undefined ? undefined : template.invitationType.toString(), true)}
                        {renderFormItem(form, SchoolLocale.InvitationTemplateFieldRegion, InvitationTemplateModelPropsName.regionId,
                            <Select size='large' {...alignPop()} disabled={disableRegion || !this.isSAEdit()} onChange={this.handleRegionChange}>{...regionOptions}</Select>, template.regionId, true)}
                        {renderFormItem(form, SchoolLocale.InvitationTemplateFieldSchool, InvitationTemplateModelPropsName.schoolId,
                            <Select size='large' {...alignPop()} disabled={disableSchool || !this.isSAEdit()}>{...schoolOptions}</Select>, template.schoolId, isSchoolRequired)}
                        {renderFormItem(form, SchoolLocale.InvitationTemplateFieldLanguage, InvitationTemplateModelPropsName.language,
                            <Select size='large' {...alignPop()} disabled={!this.isSAEdit()}>{...languageOptions}</Select>, template.language, true)}
                        {renderFormItem(form, SchoolLocale.InvitationTemplateFieldChannel, InvitationTemplateModelPropsName.invitationChannel,
                            <Select size='large' {...alignPop()} onChange={this.handleInvitationChannelChange} disabled={!this.isSARole()}>{...invitationChannelOptions}</Select>,
                            template.invitationChannel == undefined ? undefined : template.invitationChannel, true)}
                    </FormItemsLayout>
                    {renderFormItem(form, SchoolLocale.InvitationTemplateFieldSubject, InvitationTemplateModelPropsName.title,
                            <Input className="invitation-template-subject" disabled={!this.isSAEdit()} />, template.title, true)}
                    {renderFormItem(extendForm(form), SchoolLocale.InvitationTemplateFieldContent, InvitationTemplateModelPropsName.text,
                        <GLRichTextEditor
                            key={templateToolbarOptions}
                            editorHtml={template.text}
                            toolbarOptions={templateToolbarOptions}
                            toolbarCustomButtons={[
                                <InvitationTemplateTagDropDown
                                    menus={...templateTagMenus}
                                    convertSelectedValue={this.convertTemplateTag}
                                    onSelectChange={this.handleTemplateTagChange}
                                />
                            ]}
                            customDecorators={this.TemplateEditorDecorators}
                            onValueChanged={this.handleTemplateTextChange}
                            ref={this.setDomEditorRef}
                            readOnly={!this.isSAEdit()}
                        />, template.text, true, [FormHelper.ruleForCompareLength(SchoolLocale.InvitationTemplateFieldContent,
                                ComparisonOperator.LessOrEqualsThan, maxTemplateTextLength)])}
                    {isParentTemplate && renderFormItem(form, SchoolLocale.InvitationTemplateFieldBackgroundImage, InvitationTemplateModelPropsName.backgroundImage,
                        <BackgroundImage
                            file={this.backgroundImage}
                            imageLayout={backgroundImageStretch ? TemplateBackgroundImageLayout.stretch : TemplateBackgroundImageLayout.auto}
                            fileChanged={(file, fileWidth) => { this.backgroundImage = file; this.backgroundImageWidth = fileWidth }}
                        />)}
                    {isParentTemplate && renderFormItem({ ...form, ...options }, SchoolLocale.InvitationTemplateFieldBackgroundImageLayout, InvitationTemplateModelPropsName.backgroundImageLayout,
                        <Checkbox
                            onChange={(e) => this.setState({ backgroundImageStretch: e.target.checked })}>
                            <FormattedMessage id={SchoolLocale.InvitationTemplateFieldBackgroundImageLayout}></FormattedMessage>
                        </Checkbox>, template.backgroundImageLayout == TemplateBackgroundImageLayout.stretch)}
                    {renderFormItem({ ...form, ...options }, SchoolLocale.InvitationTemplateFieldDisabled, 'disabled',
                        <Checkbox disabled={!this.isSAEdit()}><FormattedMessage id={SchoolLocale.InvitationTemplateFieldDisabled}></FormattedMessage></Checkbox>, template.disabled)}
                    {!deletable &&
                        <SubmitBtns
                            previewTitle={SchoolLocale.Preview}
                            onPreview={(e) => this.onPreview(true)}
                            onCancel={this.onCancel}
                            submitAction={GSSchoolAction.NewTemplate}
                        />}
                    {deletable &&
                        <EditDelBtns
                            extraClassName='firstChild'
                            previewTitle={SchoolLocale.Preview}
                            onPreview={(e) => this.onPreview(true)}
                            delete={this.confirmDeleteItem}
                            localeId={SchoolLocale.InvitationTemplatePromptDeleteMessage}
                            confirmModalProps={{ width: deletePromptWidth }}
                            submitAction={GSSchoolAction.EditTemplate}
                            deleteAction={GSSchoolAction.DeleteTemplate}
                            isDisabled={!this.isSAEdit()}
                        />}
                </Form>
                <PreviewTemplate
                    visible={showPreviewModal && this.isSAEdit()}
                    templateHtml={this.previewText}
                    backgroundImage={this.backgroundImage}
                    backgroundImageWidth={this.backgroundImageWidth}
                    onCancel={(e) => this.onPreview(false)}
                />
            </React.Fragment>
        );
    }
}
